  <template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs pt-3" style="border: 0;">
          <li class="active"><a href="#" class="btn btn-sm btn-head text-white">Leads Customer</a></li>
        </ul>
        <div class="card new-card">
          <div class="card-body">

            <ContactManagement :show-action="true"></ContactManagement>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

  <script>
  
  import appConfig from '@src/app.config'
  import Layout from '@layouts/main'
  // import PageHeader from '@components/page-header'
  import ContactManagement from '@components/ContactManagement/ContactManagementTable'
  import Swal from 'sweetalert2'
  import { mapActions } from 'vuex'
  // import { customersData } from './dataCustomers'
  // console.log(localStorage.getItem('userPermissions'),'access')
  
  
  /**
   * Customers component
   */
  export default {
    page: {
      title: 'Leads Customer',
      meta: [
        {
          name: 'description',
          content: appConfig.description,
        },
      ],
    },
    components: {
      // Vuetable,
      // VueFilterBar,
      // VuetablePagination,
      // VuetablePaginationInfo,
      ContactManagement,
      Layout,
      // PageHeader,
    },
    data() {
      return {
        title: 'Contact Management',
        moreParams: {},
        filterText: '',
        deleteData: {},
        isLoadingTable: false,
        httpOptions: {
          // headers: {
          //   Authorization: 'Bearer ' + this.$store.state.auth.currentToken.access_token,
          // },
        },
        // apiUrl: this.$store.state.rootApi,
        items: [{
          text: 'Master',
          href: '/',
        },
        {
          text: 'Contact Management',
          href: '/contact-management',
        },
        {
          text: 'List',
          active: true,
        },
        ],
        lengthMenu: [5, 10, 25, 50, 100],
        perPage: 5,
        fields: [{
          name: 'name',
          title: 'Name',
          sortField: 'name',
        },
        {
          name: 'phone',
          title: 'Phone',
          sortField: 'phone',
        },
        {
          name: 'email',
          title: 'Email',
          sortField: 'email',
        },
        {
          name: 'address',
          title: 'Address',
          sortField: 'address',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        ],
        css: {
          pagination: {
            activeClass: 'btn-primary text-white',
            pageClass: 'btn btn-sm ',
            linkClass: 'btn btn-sm',
            icons: {
              prev: 'fas fa-arrow-left',
              next: 'fas fa-arrow-right',
            },
          },
        },
  
        dataCustomer: {
          status: 0
        },
        formClose: false
  
      }
    },
    watch: {
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh()
      },
    },
    mounted() {
      // this.data = this.customersData
      this.$events.listen('filter-set', (filterText) =>
        this.onFilterSet(filterText)
      )
      this.$events.listen('filter-reset', () => this.onFilterReset())
    },
    beforeDestroy() {
      this.$events.remove('filter-set')
      this.$events.remove('filter-reset')
    },
    methods: {
      ...mapActions('customer', ['deleteCustomer', 'saveCustomer']),
      /**
       * Table Length Change
       *
       * @return  {[type]}  [return description]
       */
      onChangeTableLength() {
        // this.$refs.vuetable.reload()
        this.$nextTick(() => this.$refs.vuetable.refresh())
      },
      /**
       * Pagination page change
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page)
      },
      /**
       * Pagination info and pagination show
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      /**
       * [onFilterSet description]
       *
       * @param   {[type]}  filterText  [filterText description]
       *
       * @return  {[type]}              [return description]
       */
      onFilterSet(filterText) {
        this.moreParams = {
          filter: filterText,
        }
        this.$nextTick(() => this.$refs.vuetable.refresh())
      },
      /**
       * Show Table Loading
       *
       * @return  {[type]}  [return description]
       */
      loadingTable() {
        this.isLoadingTable = true
      },
  
      /**
       * Hide Loading if Success
       *
       * @return  {[type]}  [return description]
       */
      loadTableSuccess() {
        this.isLoadingTable = false
      },
      /**
       * Hide Loading if Failed
       *
       * @return  {[type]}  [return description]
       */
      loadTableFailed() {
        this.isLoadingTable = false
        this.$swal({
          title: 'Terjadi Kesalahan',
          type: 'error',
          timer: '2000',
          toast: true,
          position: 'bottom-end',
        })
      },
  
      onAction(action, rowData, rowIndex) {
        if (action === 'edit-item') {
          this.$router.push({
            name: 'ContactManagement Edit',
            params: {
              id: rowData.id,
            },
          })
        } else if (action === 'detail-item') {
          this.$router.push({
            name: 'ContactManagement Detail',
            params: {
              id: rowData.id,
            },
          })
        } else if (action === 'view-item') {
          this.$router.push({
            name: 'ContactManagement View',
            params: {
              id: rowData.id,
            },
          })
        } else if (action === 'delete-item') {
          this.showConfirmDelete(rowData.id)
        }
      },
  
      showFormInput() {
        this.$modal.show('inputCustomer')
      },
      hideFormInput() {
        this.isLoadingTable = false
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
  
        Toast.fire({
          icon: 'warning',
          title: 'form input is closing..'
        })
  
        this.$modal.hide('inputCustomer')
        this.dataCustomer = {}
      },
  
      submitData() {
        // let headers = {
        //   'Content-Type': 'multipart/form-data'
        // }
  
        let headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("access_token")
        }
  
        this.formData = new FormData()
        // this.dataCustomer = 0
  
        for (var key in this.dataCustomer) {
          this.formData.append(key, this.dataCustomer[key])
          // console.log(this.formData.append(key, this.dataCustomer[key]))
        }
  
        console.log('isi formdata', this.formData)
        this.saveCustomer({ payload: this.formData, headers: headers })
          .then((res) => {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
  
            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })
            // this.$refs.vuetable.refresh
            this.$modal.hide('inputCustomer')
            // console.log(res)
            this.dataCustomer = {}
            // this.vuetable.refresh()
            //  this.$refs.vuetable.reload()
            //
          })
          .catch((err) => {
            console.log(err)
            // console.log(err.data.message)
            this.errorAlert('Failed save data')
          })
      },
  
      showConfirmDelete(id) {
        this.deleteData.id = id
        let self = this
        this.$swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this!',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.value) {
            this.$swal('Deleted!', 'Your data has been deleted.', 'error')
            self.onDelete()
          } else if (result.dismiss) {
            this.$swal('Cancelled', 'Your data is safe :)', 'error')
          }
        })
      },
      /**
       * Delete Brand
       *
       * @return  {[type]}  [return description]
       */
      onDelete() {
        // this.isLoadingTable = true
        // console.log('id delete',this.deleteData.id)
        this.deleteCustomer(this.deleteData.id)
          .then((res) => {
            this.successAlert()
            // this.isLoadingTable = false;
            this.$refs.vuetable.refresh()
          })
          .catch((err) => {
            this.errorAlert()
            console.log(err.response.message)
            // this.isLoadingTable = false;
          })
      },
    },
  }
  </script>
